import AOS from 'aos';
import MicroModal from 'micromodal';
import findVideos from './ytVideos';
import Macy from 'macy'

window.addEventListener('load', () => {

    const initHeader = () => {
        const header = document.querySelector('.header');
        if (header) {
            const toggler = header.querySelector('.header__toggler');
            const navLinks = Array.from(header.querySelectorAll('.nav a'));
            toggler.addEventListener('click', () => {
                header.classList.toggle('header--open');
            });
            navLinks.forEach((link) => {
                link.addEventListener('click', () => {
                    header.classList.toggle('header--open');
                })
            });
        }

        const langSwitch = document.querySelector('.lang-switch');
        if (langSwitch) {
            const langToggler = langSwitch.querySelector('.lang-switch__current');
            langToggler.addEventListener('click', () => {
                langSwitch.classList.toggle('lang-switch--open');
            })
        }
    };

    const initTabs = () => {
        const tabs = document.querySelector('.tabs');
        if (tabs) {
            const links = Array.from(tabs.querySelectorAll('.tabs__nav-link'));
            const tabItems = Array.from(tabs.querySelectorAll('.tab'));
            const img = document.querySelector('.scope-list__img');

            const resetTabs = () => {
                links.forEach((link) => {
                    link.classList.remove('tabs__nav-link--active');
                });
                tabItems.forEach((tab) => {
                    tab.classList.remove('tab--open');
                })
            };

            links.forEach((link) => {
                link.addEventListener('click', (e) => {
                    e.preventDefault();
                    resetTabs();
                    const el = e.target;
                    el.classList.add('tabs__nav-link--active');
                    img.src = el.dataset.image;
                    console.log(el.dataset.image);
                    document.querySelector(e.target.hash).classList.add('tab--open');
                })
            })
        }
    };

    const masonryWrapper = document.querySelector('.masonry');
    if (window.screen.width > 450 && masonryWrapper) {
        const macyInstance = new Macy({
            container: '.masonry',
            columns: 3,
            margin: 20,
            trueOrder: false,
        });
        macyInstance.recalculate();
    }

    const initTimeoutModal = (pages, id) => {
        const currPath = window.location.pathname;
        if (pages.includes(currPath)) {
            setTimeout(() => {
                MicroModal.show(id, {
                    disableFocus: true,
                });
            }, 70000);
        }
    };

    const initModalLinks = (options) => {
        const form = document.querySelector(options.form);
        if (form) {
            let linkTypeField = false;
            const openModalLinks = Array.from(document.querySelectorAll(options.link));
            if (openModalLinks) {
                if (options.source) {
                    linkTypeField = document.querySelector(options.source);
                }
                openModalLinks.forEach((link) => {
                    link.addEventListener('click', (e) => {
                        e.preventDefault();
                        MicroModal.show(options.modal, {
                            disableFocus: true,
                        });
                        if (linkTypeField) {
                            linkTypeField.value = link.dataset.source;
                        }
                    })
                })
            }
            form.addEventListener('wpcf7mailsent', (e) => {
                if(typeof options.href === 'string') {
                    location.href = options.href;
                }
                if(typeof options.href === 'function' && linkTypeField) {
                    location.href = options.href(linkTypeField.value);
                }
            }, false);
        }
    };

    const scrollToLinksInit = () => {
        const linksArray = Array.from(document.querySelectorAll('.js-scroll-to'));
        linksArray.forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                const el = e.target;
                const target = document.querySelector(el.hash);
                window.scrollTo({
                    top: target.offsetTop,
                    behavior: "smooth"
                })
            })
        })
    };

    AOS.init({
        anchorPlacement: 'center-bottom'
    });

    const initStepAnimation = (stepItem, activeClass) => {
        window.addEventListener('scroll', () => {
            const stepItems = document.querySelectorAll(stepItem);

            stepItems.forEach((step, index) => {
                const rect = step.getBoundingClientRect().y;
                if (rect < window.innerHeight / 2) {
                    stepItems.forEach(step => step.classList.remove(activeClass));
                    stepItems[index].classList.add(activeClass);
                }
            })
        });
    };

    const getCupCountDownDate = () => {
        const currDate = new Date();
        const currDay = currDate.getDay();
        if (7 - currDay) {
            currDate.setDate(currDate.getDate() + (7 - currDay));
        }
        currDate.setHours(23, 59, 59);
        return currDate.getTime();
    };

    const addZero = (num) => {
        let numString = String(num);
        if (numString.length < 2) {
            numString = '0' + numString;
        }
        return numString;
    };

    const getFridayCountDownDate = (timer) => {
        const dateString = timer.dataset.date;
        const date = new Date(dateString);
        if(date) {
            date.setHours(23, 59, 59);
            return date;
        }
        return false;
    };

    const initTimer = (timer, countDownDate) => {
        if (timer) {
            const dayContainer = timer.querySelector('#day');
            const hourContainer = timer.querySelector('#hour');
            const minuteContainer = timer.querySelector('#min');
            const secondContainer = timer.querySelector('#second');

            const interval = setInterval(function () {
                const now = new Date().getTime();
                const distance = countDownDate - now;
                dayContainer.innerHTML = addZero(Math.floor(distance / (1000 * 60 * 60 * 24)));
                hourContainer.innerHTML = addZero(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
                minuteContainer.innerHTML = addZero(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
                secondContainer.innerHTML = addZero(Math.floor((distance % (1000 * 60)) / 1000));
            }, 1000);
        }
    };

    const cupTimer = document.querySelector('.js-cup-timer');
    initTimer(cupTimer, getCupCountDownDate());

    const fridayTimer = document.querySelector('.js-friday-timer');
    if(fridayTimer) {
        const fridayCountDownDate = getFridayCountDownDate(fridayTimer);
        if (fridayCountDownDate) {
            initTimer(fridayTimer, fridayCountDownDate);
        }
    }


    const initInlineForm = (formClass, link) => {
        const form = document.querySelector(formClass);
        if(form) {
            form.addEventListener('wpcf7mailsent', (e) => {
                location.href = link;
            }, false);
        }
    };

    const initTagManager = () => {
        const tagHtml = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PBQ8WPN');`;
        const body = document.querySelector('body');
        const script = document.createElement('script');
        script.innerHTML = tagHtml;
        body.appendChild(script);
    };

    const getFridayRedirectLink = (type) => {
        switch (type) {
            case '6mo':
                return 'https://api.fondy.eu/s/I73AFJ';
            case '12mo':
                return 'https://api.fondy.eu/s/0QTM';
            case 'Lifetime':
                return 'https://api.fondy.eu/s/gWK60';
        }
    };


    MicroModal.init();
    findVideos();
    initHeader();
    initTabs();
    initTimeoutModal(['/'], 'trial-modal');
    initTimeoutModal(['/demo/'], 'demo-trial-modal');
    initModalLinks({
        form: '#trial-modal .wpcf7',
        link: '.js-trial-modal',
        modal: 'trial-modal',
        href: `${location.protocol}//${location.host}/thanks-page/`,
    });
    initModalLinks({
        form: '#open-acc-modal .wpcf7',
        link: '.js-open-acc-modal',
        modal: 'open-acc-modal',
        href: `${location.protocol}//${location.host}/thanks-page/`,
        source: '.js-open-acc-source',
    });
    initModalLinks({
        form: '#open-friday-modal .wpcf7',
        link: '.js-open-friday-modal',
        modal: 'open-friday-modal',
        href: getFridayRedirectLink,
        source: '.js-friday-source',
    });
    scrollToLinksInit();
    initStepAnimation('.demo-step-item', 'demo-step-item--active');
    initStepAnimation('.cup-step-item', 'cup-step-item--active');
    initInlineForm('.cup-form form', 'https://thetrader.top/payment/order1');
    initInlineForm('.demo-trial__form form', `${location.protocol}//${location.host}/thanks-page/`,);
    //initTagManager();
});

window.addEventListener('load', AOS.refresh);

window.addEventListener('load', () => {
    const initTagManager = () => {
        const tagHtml = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PBQ8WPN');`;
        const body = document.querySelector('body');
        const script = document.createElement('script');
        script.innerHTML = tagHtml;
        body.appendChild(script);
    };

    setTimeout(() => {
        initTagManager();
    }, 3000);
});